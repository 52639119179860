/* montserrat-200 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 200;
  src: url('./assets/fonts/montserrat-v25-latin-200.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('./assets/fonts/montserrat-v25-latin-200.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('./assets/fonts/montserrat-v25-latin-200.woff2') format('woff2'),
    /* Super Modern Browsers */ url('./assets/fonts/montserrat-v25-latin-200.woff') format('woff'),
    /* Modern Browsers */ url('./assets/fonts/montserrat-v25-latin-200.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('./assets/fonts/montserrat-v25-latin-200.svg#Montserrat')
      format('svg'); /* Legacy iOS */
}
/* montserrat-regular - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/montserrat-v25-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('./assets/fonts/montserrat-v25-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('./assets/fonts/montserrat-v25-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */ url('./assets/fonts/montserrat-v25-latin-regular.woff')
      format('woff'),
    /* Modern Browsers */ url('./assets/fonts/montserrat-v25-latin-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('./assets/fonts/montserrat-v25-latin-regular.svg#Montserrat')
      format('svg'); /* Legacy iOS */
}
/* montserrat-800 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 800;
  src: url('./assets/fonts/montserrat-v25-latin-800.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('./assets/fonts/montserrat-v25-latin-800.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('./assets/fonts/montserrat-v25-latin-800.woff2') format('woff2'),
    /* Super Modern Browsers */ url('./assets/fonts/montserrat-v25-latin-800.woff') format('woff'),
    /* Modern Browsers */ url('./assets/fonts/montserrat-v25-latin-800.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('./assets/fonts/montserrat-v25-latin-800.svg#Montserrat')
      format('svg'); /* Legacy iOS */
}
/* montserrat-300italic - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 300;
  src: url('./assets/fonts/montserrat-v25-latin-300italic.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('./assets/fonts/montserrat-v25-latin-300italic.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('./assets/fonts/montserrat-v25-latin-300italic.woff2') format('woff2'),
    /* Super Modern Browsers */ url('./assets/fonts/montserrat-v25-latin-300italic.woff')
      format('woff'),
    /* Modern Browsers */ url('./assets/fonts/montserrat-v25-latin-300italic.ttf')
      format('truetype'),
    /* Safari, Android, iOS */ url('./assets/fonts/montserrat-v25-latin-300italic.svg#Montserrat')
      format('svg'); /* Legacy iOS */
}
/* montserrat-italic - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 400;
  src: url('./assets/fonts/montserrat-v25-latin-italic.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('./assets/fonts/montserrat-v25-latin-italic.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('./assets/fonts/montserrat-v25-latin-italic.woff2') format('woff2'),
    /* Super Modern Browsers */ url('./assets/fonts/montserrat-v25-latin-italic.woff')
      format('woff'),
    /* Modern Browsers */ url('./assets/fonts/montserrat-v25-latin-italic.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('./assets/fonts/montserrat-v25-latin-italic.svg#Montserrat')
      format('svg'); /* Legacy iOS */
}
/* montserrat-800italic - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 800;
  src: url('./assets/fonts/montserrat-v25-latin-800italic.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('./assets/fonts/montserrat-v25-latin-800italic.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('./assets/fonts/montserrat-v25-latin-800italic.woff2') format('woff2'),
    /* Super Modern Browsers */ url('./assets/fonts/montserrat-v25-latin-800italic.woff')
      format('woff'),
    /* Modern Browsers */ url('./assets/fonts/montserrat-v25-latin-800italic.ttf')
      format('truetype'),
    /* Safari, Android, iOS */ url('./assets/fonts/montserrat-v25-latin-800italic.svg#Montserrat')
      format('svg'); /* Legacy iOS */
}

/* raleway-200 - latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 200;
  src: url('./assets/fonts/raleway-v28-latin-200.eot'); /* IE9 Compat Modes */
  src: local(''), url('./assets/fonts/raleway-v28-latin-200.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('./assets/fonts/raleway-v28-latin-200.woff2') format('woff2'),
    /* Super Modern Browsers */ url('./assets/fonts/raleway-v28-latin-200.woff') format('woff'),
    /* Modern Browsers */ url('./assets/fonts/raleway-v28-latin-200.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('./assets/fonts/raleway-v28-latin-200.svg#Raleway') format('svg'); /* Legacy iOS */
}
/* raleway-regular - latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/raleway-v28-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('./assets/fonts/raleway-v28-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('./assets/fonts/raleway-v28-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */ url('./assets/fonts/raleway-v28-latin-regular.woff') format('woff'),
    /* Modern Browsers */ url('./assets/fonts/raleway-v28-latin-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('./assets/fonts/raleway-v28-latin-regular.svg#Raleway')
      format('svg'); /* Legacy iOS */
}
/* raleway-800 - latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 800;
  src: url('./assets/fonts/raleway-v28-latin-800.eot'); /* IE9 Compat Modes */
  src: local(''), url('./assets/fonts/raleway-v28-latin-800.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('./assets/fonts/raleway-v28-latin-800.woff2') format('woff2'),
    /* Super Modern Browsers */ url('./assets/fonts/raleway-v28-latin-800.woff') format('woff'),
    /* Modern Browsers */ url('./assets/fonts/raleway-v28-latin-800.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('./assets/fonts/raleway-v28-latin-800.svg#Raleway') format('svg'); /* Legacy iOS */
}
/* raleway-300italic - latin */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 300;
  src: url('./assets/fonts/raleway-v28-latin-300italic.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('./assets/fonts/raleway-v28-latin-300italic.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('./assets/fonts/raleway-v28-latin-300italic.woff2') format('woff2'),
    /* Super Modern Browsers */ url('./assets/fonts/raleway-v28-latin-300italic.woff')
      format('woff'),
    /* Modern Browsers */ url('./assets/fonts/raleway-v28-latin-300italic.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('./assets/fonts/raleway-v28-latin-300italic.svg#Raleway')
      format('svg'); /* Legacy iOS */
}
/* raleway-italic - latin */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 400;
  src: url('./assets/fonts/raleway-v28-latin-italic.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('./assets/fonts/raleway-v28-latin-italic.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('./assets/fonts/raleway-v28-latin-italic.woff2') format('woff2'),
    /* Super Modern Browsers */ url('./assets/fonts/raleway-v28-latin-italic.woff') format('woff'),
    /* Modern Browsers */ url('./assets/fonts/raleway-v28-latin-italic.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('./assets/fonts/raleway-v28-latin-italic.svg#Raleway')
      format('svg'); /* Legacy iOS */
}
/* raleway-800italic - latin */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 800;
  src: url('./assets/fonts/raleway-v28-latin-800italic.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('./assets/fonts/raleway-v28-latin-800italic.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('./assets/fonts/raleway-v28-latin-800italic.woff2') format('woff2'),
    /* Super Modern Browsers */ url('./assets/fonts/raleway-v28-latin-800italic.woff')
      format('woff'),
    /* Modern Browsers */ url('./assets/fonts/raleway-v28-latin-800italic.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('./assets/fonts/raleway-v28-latin-800italic.svg#Raleway')
      format('svg'); /* Legacy iOS */
}
